'use strict';

import $ from 'jquery';
import firebase from 'firebase';

export default class Followus {
  constructor() {

    const database = firebase.database();

    let ref = database.ref("cache").limitToLast(6);

    $(document).ready(function () {

      ref.on('value', function (snap) {
        let articles = $(".suivez-nous");
        let fb = 0;
        let ln = 0;
        let tw = 0;
        // console.log('snap.val()', snap.val());

        snap.forEach(function (child) {
          if (child.val().origin == "facebook") {
            fillArticle(child.val(), articles.find('.article-container'), 0, fb);
            fb++;
          }
          else if (child.val().origin == "linkedin") {
            fillArticle(child.val(), articles.find('.article-container'), 1, ln);
            ln++;
          }
          else if (child.val().origin == "twitter") {
            fillArticle(child.val(), articles.find('.article-container'), 2, tw);
            tw++;
          }
        });
      });
    });

    function fillArticle(res, articles, i, b) {
      // console.log(i,' : ', res);
      if (articles[i]) {
        // let spin = articles[i].children[0].children[0].children[0];
        // if (spin) {
          // spin.remove();
          // let img = articles[i].children[0].children[0];
        let img;
        let txt;
        if(b == 0){
          img = $(articles[i]).find('.image')[0];
          // console.log('img', img);
          txt = $(articles[i]).find('.text');
        }else{
          img = $(articles[i]).find('.image')[1];
          // console.log('img', img);
          txt = $(articles[i]).find('.text.b');
        }
        // console.log('img', img);
        // console.log('txt', txt);

          let toDisplay = res.message || res.title;

          if (res.media) {
            // img.style.backgroundImage = `url(${res.media})`;
            img.src = res.media;
          }
          if (toDisplay) {
            txt.html(toDisplay.substring(0, 40) + '...');
          }
          txt.attr('href', res.url);
        // }
      }
    }
  }
}
