// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
// var $ = require('jquery');
import firebase from 'firebase';
import 'foundation-sites';
// var foundation = require('foundation-sites/dist/foundation.min.js');

import Map from '../_modules/map/map';
//import Header from '../_modules/header/header';
import Morph from '../_modules/morph/morph';
import Meetus from '../_modules/meetus/meetus';
import config from './firebase/configFirebase';
import Followus from '../_modules/followus/followus';
import Header from '../_modules/header/header';
import ContentHeader from '../_modules/contentheader/contentheader';

firebase.initializeApp(config);

let userId = '';

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    userId = user.uid;
    new Meetus(userId);
  }
  if (!user) {
    firebase.auth().signInAnonymously().catch(function (error) {
      console.log(error);
    });
  }

});

$(() => {

  const baseUrl = window.location.pathname === '/' ? './' : '../';

  new ContentHeader();
  new Morph('b');
  new Morph('a');

  const map = $('#map');

  if (map.length) {
    new Map('map', baseUrl + 'images/esper.png');
  }
  new Followus();
  new Header();


  $(document).ready(function($) {
    $(document).foundation();
  });

});
