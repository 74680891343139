'use strict';

import $ from 'jquery';
import './morphPlugin';
import {TimelineLite} from "gsap";

export default class Morph {
  constructor(id) {

    if (id) {
      var tl = new TimelineLite(
        {
          onUpdate: updateSlider,
          onComplete: function () {
            this.restart()
          }
        }
      );

      var morph = $(`#${id}morph`);

      if ($(`#${id}morph`).length > 0) {

        var step0 = document.getElementById(id +"step00");
        if (step0) {
          tl.to(step0, 1, {morphSVG: "#" + id + "step01"}, "+=")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step02"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step03"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step04"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step05"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step06"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step07"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step08"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step09"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step10"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step11"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1")
            .to(step0, 1, {morphSVG: "#" + id + "step12"}, "+=4")
            .to(step0, 1, {morphSVG: step0}, "+=1");
        }
      }
    }

    function updateSlider() {
      $("#slider").slider("value", tl.progress());
    }
  }
}
