'use strict';

import $ from 'jquery';
import request from 'request';
import firebase from 'firebase';

export default class Meetus {
  constructor(userId) {

    const database = firebase.database();

    let ref = database.ref('contacts').child(userId);

    // $(document).ready(function () {
    //   $('#submit').prop('disabled', true);
    //   $('input').keyup(function () {
    //     $('#submit').prop('disabled', this.value == "" ? true : false);
    //   })
    // });
    

    $("form").submit(function (event) {
      event.preventDefault();
      var finalForm = {};

      var rawForm = $(this).serializeArray();
      console.log('rawForm', rawForm);
      rawForm.map(function (input) {
        finalForm[input.name] = input.value;
      });
      finalForm.date = new Date().toISOString();
      console.log('finaleForm', finalForm);
      if (finalForm.email && finalForm.name && finalForm.message) {

        request.post('https://corpo-cluster.digitalandyou.com/mail/', {json: {form: finalForm}}, function (error, response, body) {
            if (!error && response.statusCode == 200) {
              console.log(body);
              ref.push(finalForm, function () {

                var form = document.getElementById('contact');
                form.reset();

                $('#modal').foundation('open');

              }).catch((error)=> {
                console.log('error', error);
                $('#modalError').foundation('open');
              });
            }
            else {
              console.log('error', error);
              $('#modalError').foundation('open');
            }
          }
        );
      } else {

        $('#modalError').foundation('open');
      }


    });
  }
}
