'use strict';

// import $ from 'jquery';

export default class Contentheader {
  constructor() {
    // let video = $('#bgvid');
    //
    // if (video) {
    //   video.on('ended', ()=>{
    //     video.remove();
    //   });
    // }
  }
}
