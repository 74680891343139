'use strict';

export default class Map {
  constructor(mapId, marker) {

    const map = new google.maps.Map(document.getElementById(mapId), {
      center: {lat: 48.8720546, lng: 2.341613999999936},
      zoom: 15,
      navigationControl: false,
      mapTypeControl: false,
      disableDefaultUI: true,
      scaleControl: false,
      draggable: false,
      scrollwheel: false
    });

    const image = marker;

    new google.maps.Marker({
      map: map,
      position: {lat: 48.8720546, lng: 2.341613999999936},
      title: 'Digital & You',
      icon: image
    });
  }
}
